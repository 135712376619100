/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.portfolioMainBlock{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px 100px;
}

.portfolioInfo {
    display: flex;
}

.textBlock {
    width: 50%;
    padding-right: 15px;
    color: aliceblue;
}

.textBlock h1 {
    padding-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 60px;
}

.textBlock h3 {
    padding-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-size: 21px;
}

.textBlock p {
    padding-bottom: 50px;
    font-family: "Raleway", sans-serif;
    text-align: justify;
}

.imageBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    width: 50%;
}



.portfolioGallery {
    color: aliceblue;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 50px;
}

.media {
    max-width: 300px;
    overflow: hidden;
} 

.media img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; 
}

.videogalery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.gallery {
    width: 100%;
    height: auto;
}

.gallery .ReactGridGallery {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
} */


/* body {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #282c34;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
} */

.textBlock {
    width: 65%;
    color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    margin-top: 20px;
}

.textBlock h1 {
    padding-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 60px;
}

.textBlock h3 {
    padding-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-size: 21px;
}

.textBlock p {
    padding-bottom: 50px;
    font-family: "Raleway", sans-serif;
    text-align: center;
}

.container {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vw;
}

.comingSoon {
    font-size: 3rem;
    position: relative;
}

.heading{
    color: white;
    background-image: radial-gradient(circle, rgba(255, 255, 255, 0.2) 1%, rgba(0, 0, 0, 0) 80%);
    font-weight: 400;
    height: 60px;
    display: flex;
    justify-content: center;
    font-size: 35px;
}

.portfolio_video {
    display: flex;
    justify-content: center;
    margin: 50px;
    gap: 100px;
}

.portfolio_video iframe{
    height: 400px;
    width: 600px;
    margin: 0;
}

@media (max-width: 1024px) {
    .heading{
        background-image: radial-gradient(circle, rgba(255, 255, 255, 0.2) 1%, rgba(0, 0, 0, 0) 100%);
    }

    .portfolio_video iframe{
        height: 500px;
        width: 100%;
    }

    .portfolio_video {
        flex-wrap: wrap;
        margin: 0;
        padding: 50px 20px 30px 20px;
        gap: 50px;
    }
}

@media (max-width: 768px) {
    .comingSoon {
        font-size: 2rem;
    }

    .portfolio_video iframe{
        height: 400px;
        width: 100%;
    }

    .portfolio_video {
        flex-wrap: wrap;
        margin: 0;
        padding: 50px 15px 30px 15px;
        gap: 50px;
    }
}

@media (max-width: 480px) {
    .comingSoon {
        font-size: 1.5rem;
    }

    .textBlock h1 {
        font-size: 40px;
        font-weight: 500;
    }

    .textBlock h3 {
        font-size: 13px;
    }

    .portfolio_video {
        flex-wrap: wrap;
        margin: 0;
        padding: 40px 10px 20px 10px;
        gap: 40px;
    }

    .portfolio_video iframe {
        height: 300px;
    }
}