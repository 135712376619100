@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.first_text_block {
    width: 100%;
    height: 150px;
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    gap: 15px;
    /* align-content: center; */
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.text p {
    display: flex;
    justify-content: center;
    font-family: "Raleway", sans-serif;
    font-size: 21px;
    position: absolute; /* Adjust distance between text and block */
    /* padding-top: 125px;  */
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    color: white;
}

.logo img {
    display: flex;
    justify-content: center;
    width: 220px;
    /* height: 200px; */
    object-fit: contain;
}

/* MEDIA */

@media (max-width: 1024px) {
    .text p{
        font-size: 18px;
    }
}

@media (max-width: 768px){
    .text p{
        font-size: 16px;
    }
    .logo img{
        width: 180px;
    }
}

@media (max-width: 480px) {
    .logo img{
        width: 160px;
    }
    .first_text_block{
        margin-top: 10px;
        gap: 40px;
    }
    .first_text_block p{
        padding: 0 25px;
    }
}  