@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.mainBlock{
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
    display: grid;
    grid-template-columns: repeat(3, 450px);
    grid-template-rows: repeat(1, 250px);
    justify-content: center;
    grid-column-gap: 65px;
    padding-top: 50px;
}

.linkBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 450px;
}

.linkBlock:nth-child(1) {
    background-image: url('../../media/link-block-img-1.jpg');
    background-repeat: no-repeat;
    background-size: 750px auto;
    background-position-x: -300px;
    /* transition: background-color 0.5s; */
}
.linkBlock::before {
    position: absolute;
    content: "";
    width: 450px;
    height: 250px; 
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.5s; 
    z-index: 1;
}

.linkBlock:hover::before {
    background-color: rgba(0, 0, 0, 0.7); /* Цвет фона при наведении */
}
  
.linkBlock:nth-child(2) {
    background-image: url('../../media/link-block-img-2.jpg');
    background-repeat: no-repeat;
    background-size: 680px auto;
    background-position-x: -10px;
    background-position-y: -80px;
}
  
.linkBlock:nth-child(3) {
    background-image: url('../../media/link-block-img-3.jpg');
    background-repeat: no-repeat;
    background-size: 450px auto;
    background-position-y: -220px;
}

.button{
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 45px;
    background-color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    color: black;
    transition: .2s ease-in-out;
    z-index: 2;
}

.button:hover{
    background-color: white;
}

/* MEDIA */

@media (max-width: 1024px) {
    .mainBlock{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 480px) {
    .linkBlock{
        height: 200px;
        width: 300px;
    }
    .linkBlock::before{
        height: 200px;
        width: 300px;
    }
    .linkBlock:nth-child(1){
        background-size: 650px auto;
    }
    .linkBlock:nth-child(2){
        background-size: 580px auto;
        background-position-y: -60px;
    }
    .linkBlock:nth-child(3){
        background-size: 350px auto;
        background-position-y: -170px;
    }
} 