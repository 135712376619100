@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.textBlock{
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    word-spacing: 2px;
}

.text1{
    font-weight: 700;
}

.text2{
    font-weight: 300;
    font-size: 18px;
}

@media (max-width: 480px) {
    .text1{
        font-size: 15px;
        text-align: center;
    }
    .text2{
        font-size: 13px;
    }
} 