@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
    margin: 0;
}

.headBlock {
    background-image: url('../../media/videoProd/videoProdBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;

    width: 100%;
    height: 1000px;
}

.informBlock {
    display: flex;
    padding: 55px 0px 0px 130px;
    color: white;
    /* gap: 75px; */
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0) 70%);
    z-index: 1;
    position: relative;
}

.informBlock_left {
    width: 55%;
    margin-top: -260px;
}

.informBlock_left img{
    width: 50px;
    padding-top: 75px;
}

.informBlock_left::after{
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    margin-top: 70px;
}

.informBlock_left hr{
    width: 545px;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    margin: 30px 0 30px 0;
}

.informBlock_left h1 {
    font-size: 75px;
    line-height: 70px;
}

.informBlock_left h6 {
    width: 500px;
    height: 70px;
    font-size: 30px;
    font-weight: 700;
    color: black;
    background-color: white;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.informBlock_left a{
    text-decoration: none;
}

.informBlock_right {
    width: 60%;
    padding: 30px 0px 40px;
}

.informBlock_right h6 {
    font-size: 20px;
    background-color: white;
    color: black;
    padding: 10px 30px;
}

.informBlock_right p {
    padding: 20px 30px 0px 15px;
    font-style: italic;
}

.informBlock_right hr{
    width: 250px;
    margin: 35px 0px -35px 220px;
}

.informBlock_right strong{
    font-style: normal;
}

.checkList{
    display: flex;
}

.checkList img{
    width: 25px;
}

.additionalText{
    text-align: center;
}

.aboutBlock {
    /* margin-top: 80px; */
    display: flex;
    color: white;
}

.aboutInfo {
    width: 50%;
    display: flex;
    align-items: flex-start;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0) 70%);
    padding: 40px 0px 40px;
    margin-top: -175px;
}

.aboutProfPic img {
    height: 380px;
    filter: brightness(90%);
    /* width: auto; */
}

.aboutH5 {
    font-size: 45px;
    transform: rotate(-90deg);
    /* margin: 120px -20px 0px 0px */
    align-self: center;
    width: 80px;
}

.aboutText h6 {
    font-size: 20px;
    padding: 0px 20px 0px 30px;
}

.aboutText p {
    padding: 15px 25px 0px 30px;
    font-size: 14px;
    position: relative;
    z-index: 2;
}

.aboutSecondBlock {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0px auto;
    padding-bottom: 25px;
}

.text1{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.text1 img{
    width: 100px;
}

.text2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
}

.text2 p{
    text-align: center;
}

.text2 span{
    font-style: italic;
}

.portfolioLine{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70px;
    font-size: 40px;
    font-weight: 700;
    background-color: white;
}

.portfolioBlock{
    display: flex;
}

.portfolioBlock img{
    width: 100px;
}

.portfolioBlock{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 50px 15px 50px 15px;
}

.videoDiv {
    display: flex;
    justify-content: space-between;
    gap: 100px;
}

.videoPlaying{
    width: 250px;
    position: relative;
    cursor: pointer;
}

.videoPlaying::after {
    display: none;
}

.video{
    width: 250px;
    position: relative;
    cursor: pointer;
}

.video::after {
    content: '';
    background-image: url('../../media/videoProd/playButton.png');
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.priceLine{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100px;
    font-size: 40px;
    font-weight: 700;
    background-color: white;
}

.priceLine span{
    font-size: 30px;
    font-style: italic;
}

.packagesBlock {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    padding: 20px 70px 50px 70px;
    color: white;
}

.packagesBlock li {
    font-size: 14px;
    text-align: center;
}

.packOne,
.packTwo,
.packThree {
    display: flex;
    flex-direction: column;
}

.packOne img,
.packTwo img,
.packThree img {
    width: 300px;
    height: 90px;
}

.packOne button,
.packTwo button,
.packThree button {
    width: 130px;
    height: 50px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.packOne button:hover,
.packTwo button:hover,
.packThree button:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}

.packOneInfo,
.packTwoInfo,
.packThreeInfo {
    border: 1px solid white;
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    line-height: 15px;
    padding: 20px;
}

.packOneInfo h1,
.packTwoInfo h1,
.packThreeInfo h1 {
    margin-bottom: 20px;
}

.packOneInfo h2,
.packTwoInfo h2,
.packThreeInfo h2 {
    margin-top: 20px;
}

.packPhoto img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* padding-bottom: 10px; */
}
.packPhoto{
    width: 90%;
    height: 200px;
    overflow: hidden;
    object-fit: cover;
}
.importantBlock {
    width: 100%;
    height: auto;
    background-color: #CBC7B7;
    padding: 40px 60px 40px 10%;
    display: flex;
    justify-content: space-between;
}
  
.impBTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
} 

.impBTitle img {
    height: 125px;
    width: auto;
}

.impBList{
    line-height: 30px;
}

@media (max-width: 1024px) {
    .headBlock{
        height: auto;
    }
    .informBlock_left::after{
        display: none;
    }
    .informBlock{
        flex-direction: column;
        padding: 50px 0 0 0;
    }
    .informBlock_left{
        display: flex;
        flex-direction: column;
        margin: 30px 0;
        width: 100%;
        text-align: center;
    }
    .informBlock_right{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .informBlock_right h6{
        width: 100%;
        text-align: center;
    }
    .checkList{
        width: 75%;
    }
    .checkList p{
        margin: 0;
        padding: 20px 30px 0px 10px;
        height: 58px;
    }
    .checkList img{
        margin: 0;
    }
    .aboutBlock{
        flex-direction: column;
    }
    .aboutInfo{
        width: 100%;
        margin: 0;
    }
    .aboutSecondBlock{
        padding: 25px;
    }
    .videoDiv{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
    }
    .packagesBlock{
        display: flex;
        flex-direction: column;
        /* width: 600px; */
    }
    .packOneInfo,
    .packTwoInfo,
    .packThreeInfo{
        width: 500px;
    }
    .importantBlock{
        padding: 40px 60px 40px 3%;
        gap: 10px;
    }
    .impBTitle{
        font-size: 30px;
    }
 } 
 
 @media (max-width: 768px) {
    .importantBlock{
        flex-direction: column;
        padding: 30px;
        gap: 0;
    }
    .impBTitle{
        flex-direction: column;
        text-align: center;
        font-size: 20px;
    }
    .impBTitle img{
        rotate: 90deg;
        height: 80px;
    }
    .impBList{
        width: 90%;
    }
 } 
 
 @media (max-width: 480px) {
    .informBlock_left h1{
        font-size: 40px;
        line-height: normal;
    }
    .informBlock_left hr{
        width: 90%;
    }
    .informBlock_left h6{
        width: 90%;
        padding: 10px;
        font-size: 20px;
        height: auto;
        border-radius: 50px;
        margin: 0 auto;
    }
    .informBlock_left a{
        width: 80%;
    }
    .informBlock_right{
        height: auto;
    }
    .checkList{
        width: 90%;
    }
    .checkList p{
        height: auto;
        padding: 20px 0px 0px 10px;
    }
    .aboutBlock{
        justify-content: center;
    }
    .aboutInfo{
        flex-direction: column;
        justify-content: center;
        text-align: center;
        background-image: none;
    }
    .aboutInfo .aboutH5{
        transform: none;
        width: 100%;
        text-align: center;
    }
    .aboutProfPic{
        margin: 10px auto;
    }
    .aboutSecondBlock{
        text-align: center;
    }
    .portfolioBlock img{
        display: none;
        justify-content: center;
    }
    .videoDiv{
        grid-template-columns: 1fr;
    }
    .packagesBlock{
        gap: 25px;
        padding: 20px 20px 50px 20px;
    }
    .packagesBlock img{
        margin: 0;
    }
    .packOne,
    .packTwo,
    .packThree{
        width: 100%;
    }
    .packOne button,
    .packTwo button,
    .packThree button{
        color: black;
    }
    .packOneInfo,
    .packTwoInfo,
    .packThreeInfo{
        width: 100%;
        padding: 20px;
        text-align: center;
    }
    .packOneInfo h1,
    .packTwoInfo h1,
    .packThreeInfo h1{
        width: 90%;
        font-size: 20px;
        margin-top: 10px;
    }
 } 