@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.modal {
  z-index: 20;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center; 
  font-family: "Montserrat", sans-serif;
}
  
.open {
  display: flex;
}
  
.modalContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  width: 600px;
  height: fit-content;
  background-color: #adb5bdee;
  border: 2px solid black;
}
  
.modalTitle {
  font-weight: 500;
}
  
.modalClose {
  position: absolute;
  top: 5px;
  right: 20px;
  cursor: pointer;
  font-size: 30px;
  font-weight: bold;
}
  
.modalBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modalBody p{
  text-align: center;
}

.modalBody a{
  text-decoration: none;
  color: black;
}

.modalBody a:hover{
  color: rgb(88, 88, 88);
}

.cardPhoto{
  width: 175px;
  height: 175px;
  border-radius: 50%;
  overflow: hidden;
}
 
.cardPhoto img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.contactLink {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contactLink svg{
  margin: 0;
}

.contactLink a{
  border: 1px solid  rgb(88, 88, 88);
  border-radius: 10px;
  width: 250px;
  padding: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.info{
  font-size: 13px;
}

@media (max-width: 480px) {
  .modalBody{
    gap: 10px;
  }
  .cardPhoto{
    width: 100px;
    height: 100px;
  }
  .modalBody p{
    font-size: 10px;
  }
  .contactLink a{
    padding: 10px;
  }
} 