.container {
    display: grid;
    padding: 15px 50px 50px 50px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(8, 110px);
    gap: 30px;
}

.lazyloadElem{
    display: grid;
    height: 100%;
    /* object-fit: cover; */
}

.item{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    opacity: 1;
    transition: opacity 0.8s ease; 
}

.item:hover::before{
    opacity: 0; 
}

.container .lazyloadElem:nth-child(1) {
    grid-area: 1/1/5/2;
    overflow: hidden;
    position: relative;
}

.container .lazyloadElem:nth-child(2) {
    grid-area: 1/2/5/3;
    overflow: hidden;
    position: relative;
}

.container .lazyloadElem:nth-child(3) {
    grid-area: 1/3/5/4;
    overflow: hidden;
    position: relative;
}

.container .lazyloadElem:nth-child(4) {
    grid-area: 1/4/5/5;
    overflow: hidden;
    position: relative;
}

.container .lazyloadElem:nth-child(5) {
    grid-area: 5/1/9/2;
    overflow: hidden;
    position: relative;
    object-fit: contain; 
}

.container .lazyloadElem:nth-child(5) img {
    object-position: -300px;
    /*or we can use 
    object-position: right;*/
} 

.container .lazyloadElem:nth-child(6) {
    grid-area: 5/2/9/3;
    overflow: hidden;
    position: relative;
}

.container .lazyloadElem:nth-child(7) {
    grid-area: 5/3/7/4;
    overflow: hidden;
    position: relative;
    place-items: center;
}

.container .lazyloadElem:nth-child(8) {
    grid-area: 7/3/9/4;
    overflow: hidden;
    position: relative;
}

.container .lazyloadElem:nth-child(9) {
    grid-area: 5/4/7/5;
    overflow: hidden;
    position: relative;
}

.container .lazyloadElem:nth-child(10) {
    grid-area: 7/4/9/5;
    overflow: hidden;
    position: relative;
}

.container .lazyloadElem:nth-child(n + 7) {
    /* overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center; 
    align-items: center; 
    object-fit: cover; */
    align-content: center;
}

@media (max-width: 768px){
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: auto; 
    }

    .container .lazyloadElem:nth-child(n + 1) {
        grid-area: auto;
    }

    .container .lazyloadElem:nth-child(5) img{
        object-position: center;
    }
}

@media (max-width: 480px) {
    .container{
        padding: 15px 25px 50px 25px;
    }
} 